import chroma from 'chroma-js'
import unset from 'lodash-es/unset'
import { colors } from '../../../themeConfig'
import DataSeries from './DataSeries'

/**
 * Class for representing an e-chart line graph with line series,
 * yAxis value max 100.
 *
 * You're supposed to feed this class' option property to the e-chart component "options" property.
 * The option property here is updated whenever resyncOptions is called, that is mainly when adding series
 * or removing them.
 */
export default class PercentualLineGraph {
  data: Map<String, DataSeries>
  categories: Array<any>
  seriesType: string = 'line'
  yAxisName: string = ''
  option: {
    yAxis: any
    xAxis: any
    series: Array<any>
    legend: {
      data: Array<any>
    }
    color: Array<any>
  }
  params: any = {}

  constructor() {
    this.data = new Map()
    this.categories = []
    this.option = {
      yAxis: this.yAxis(),
      xAxis: this.xAxis(),
      series: [],
      legend: {
        data: [],
      },
      color: [],
    }
  }

  clear() {
    this.categories = []
    this.data.clear()
    this.params = { kind: 'score' }
    this.resyncOptions(this.params)
  }

  addSeries(id: String, data: DataSeries) {
    if (this.data.has(id)) {
      console.warn('Tried to add already existing data series to line graph.')
    } else {
      this.data.set(id, data)
      this.resyncOptions(this.params)
    }
  }

  removeSeries(id) {
    if (this.data.has(id)) {
      this.data.delete(id)
      this.resyncOptions(this.params)
    } else {
      console.warn('Tried to remove unexisting data set from line graph.')
    }
  }

  setCategories(categories: Array<any>) {
    this.categories = categories
    this.resyncOptions(this.params)
  }

  setData(data) {
    this.data = data
    return this
  }

  getData() {
    return this.data
  }

  resyncOptions(params = {}) {
    const series = this.getDataSeriesInformation(params)
    const themeColors = { ...colors }
    unset(themeColors, 'dark')
    this.option = {
      yAxis: this.yAxis(),
      xAxis: this.xAxis(),
      series: series.data,
      legend: {
        data: series.legends,
      },
      color: chroma
        .scale(Object.values(themeColors))
        .mode('lch')
        .colors(this.data.size),
    }
  }

  /** Gets object containing the normalized data series and
   *  its supposed legend.
   */
  getDataSeriesInformation(params) {
    const data: Array<any> = []
    const legends: Array<String> = []
    this.data.forEach((dataSeries, key) => {
      data.push({
        name: dataSeries.legend(),
        type: dataSeries.getType(),
        data: dataSeries.normalized(params),
      })
      legends.push(dataSeries.legend())
    })

    return {
      data,
      legends,
    }
  }

  xAxis() {
    return {
      type: 'category',
      data: this.categories,
    }
  }

  yAxis() {
    return {
      name: this.yAxisName,
      type: 'value',
      max: 100,
    }
  }
}

/**
 * Observation: BEWARE
 * Vue 2 doesnt yet support javascript Map object type reactivity,
 * thats why I created reSyncOptions.
 * author:G370
 */
